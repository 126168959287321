import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import AOS from 'aos';
import Accordion from 'accordion-js';

document.addEventListener('DOMContentLoaded', () => {
    function showFaqList() {
      var elements = document.getElementsByClassName("js-faq-item");
      for (const x of elements) {
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }
    }

    document.getElementById('js-faq-list-more-link').addEventListener('click', function(e){
      const top = document.documentElement.scrollTop;
      e.preventDefault();
      this.style.display = 'none';
      showFaqList();

      window.scrollTo(0, top);
    });

    if (document.querySelector('.ac-cont')) {
        new Accordion('.ac-cont', {
            duration: 300,
        });
    }

    const heroFigureTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.hero',
            start: 'top top',
            scrub: true,
            end: '100%'
        }
    });
    heroFigureTl.to('.hero__figure-inner-holder', 1, { y: -100 });
    const newsTlWhite = gsap.timeline({
        scrollTrigger: {
            trigger: '.how-bg-white',
            start: 'top bottom',
            scrub: 1,
            end: '100%'
        }
    });
    const newsTlBlue = gsap.timeline({
        scrollTrigger: {
            trigger: '.how-bg-white',
            start: 'top bottom',
            scrub: 1,
            end: '100%'
        }
    });


    newsTlWhite.to('.how-bg-white', 1, { y: '-130' })
    newsTlBlue.to('.how-bg-blue', 1, { y: '-110' })

    const faqTlBig = gsap.timeline({
        scrollTrigger: {
            trigger: '.faq-bg__big',
            start: 'top bottom',
            scrub: 1,
            end: '100%'
        }
    });
    const faqTlSmall = gsap.timeline({
        scrollTrigger: {
            trigger: '.faq-bg__small',
            start: 'top bottom',
            scrub: 1,
            end: '100%'
        }
    });

    faqTlBig.to('.faq-bg__big', 1, { y: -170 })
    faqTlSmall.to('.faq-bg__small', 1, { y: -130 })

    AOS.init({
        startEvent: 'DOMContentLoaded',
        once: true,
        easing: 'ease-in-out',
        offset: 150,
        duration: 350,
    });
});
