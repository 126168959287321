document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.header');
    const body = document.body;
    window.addEventListener('scroll', () => {
        if (body.getBoundingClientRect().top < -200) {
            header.classList.add('is-modified');
        } else {
            header.classList.remove('is-modified');
        }
    });
    const triggerBtn = document.querySelector('.header-burger');
    if (triggerBtn) {
        triggerBtn.addEventListener('click', () => {
            body.classList.toggle('menu-opened');
        });
    }
});